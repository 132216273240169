import React from "react";
import "./style.css";

/**
 * Componente LoadMessage.
 *
 * @component
 *
 * @description
 * O componente `LoadMessage` exibe uma animação de carregamento com três círculos e suas respectivas sombras,
 * proporcionando um efeito visual dinâmico durante a exibição de uma mensagem de carregamento. A animação é estilizada
 * através das classes CSS `circle`, `shadow` e `wrapper`, definidas no arquivo `style.css`.
 *
 * @returns {JSX.Element} O componente que exibe a animação de carregamento.
 *
 * @example
 * return <LoadMessage />;
 */
const LoadMessage = () => {
  return (
    <>
      <div className="wrapper mt-[10px]" data-testid="wrapper">
        <div className="circle" data-testid="circle"></div>
        <div className="circle" data-testid="circle"></div>
        <div className="circle" data-testid="circle"></div>
        <div className="shadow" data-testid="shadow"></div>
        <div className="shadow" data-testid="shadow"></div>
        <div className="shadow" data-testid="shadow"></div>
      </div>
    </>
  );
};

export default LoadMessage;
