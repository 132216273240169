import React, { useEffect, useState, useRef } from "react";
import { motion } from "framer-motion";
import Ellipsis from "../Ellipisis/Ellipisis";
import { useChatContext } from "../../../contexts/ChatContext";
import { getHistoric } from "../../../server/getHistoric";

/**
 * Componente que exibe o histórico de conversas do chat.
 *
 * @component
 * @param {Object[]} history - Lista de conversas agrupadas por data.
 * @returns {JSX.Element} O componente de histórico de chat.
 */
export default function ChatHistory({ history }) {
  const {
    chatData,
    setChatData,
    setMessages,
    setCurrentChatProtocol,
    setCurrentChatHistoric,
    setCurrentChatKnowledge,
    setShowReportScreen,
    setCurrentSelection,
    setCurrentChatType,
    currentHistoryPagination,
    setCurrentHistoryPagination,
    resetPagination,
    setCurrentChatSummary,
  } = useChatContext();

  const [ellipisisVisible, setEllipisisVisible] = useState(null);
  const [lastScrollPosition, setLastScrollPosition] = useState(0);
  const [scrollCheckpoint, setScrollCheckpoint] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const containerRef = useRef(null);

  /**
   * Efeito que gerencia a rolagem do histórico de chat e carrega mais dados quando o usuário atinge 75% de rolagem.
   */
  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current && !loading && !isFetching) {
        const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
        const scrollPosition = scrollTop / (scrollHeight - clientHeight);
        const currentScrollCheckpoint = Math.floor((scrollPosition * 100) / 75);

        if (
          scrollPosition > 0.75 &&
          currentScrollCheckpoint > scrollCheckpoint
        ) {
          setLastScrollPosition(scrollTop);
          setScrollCheckpoint(currentScrollCheckpoint);
          onScrollPast75Percent();
        }
      }
    };

    const currentContainerRef = containerRef.current;
    currentContainerRef.addEventListener("scroll", handleScroll);

    return () => {
      if (currentContainerRef) {
        currentContainerRef.removeEventListener("scroll", handleScroll);
      }
    };
  }, [lastScrollPosition, scrollCheckpoint, loading, isFetching]);

  /**
   * Função chamada ao atingir 75% de rolagem. Carrega mais histórico de chat.
   */
  const onScrollPast75Percent = () => {
    if (!loading && currentHistoryPagination !== null) {
      setLoading(true);
      setIsFetching(true);
      const newPagination = currentHistoryPagination + 1;

      getHistoric(newPagination)
        .then((data) => {
          const existingIds = new Set(
            chatData.history.map((chat) => chat.protocol)
          );
          const newChats = data.history.filter(
            (chat) => !existingIds.has(chat.protocol)
          );

          if (newChats.length === 0) {
            setLoading(false);
            setIsFetching(false);
            return;
          }

          if (data.detail) {
            setCurrentHistoryPagination(null);
            setLoading(false);
            setIsFetching(false);
            return;
          }

          setChatData((prevChatData) => {
            const updatedHistory = [
              ...prevChatData.history,
              ...newChats.filter(
                (newChat) =>
                  !prevChatData.history.some(
                    (chat) => chat.protocol === newChat.protocol
                  )
              ),
            ];
            return {
              ...prevChatData,
              history: updatedHistory,
            };
          });

          setScrollCheckpoint(0);
          setLastScrollPosition(0);
        })
        .catch((error) => {
          setCurrentHistoryPagination(null);
          setLoading(false);
          setIsFetching(false);
        })
        .finally(() => {
          setLoading(false);
          setIsFetching(false);
          setCurrentHistoryPagination(newPagination);
        });
    }
  };

  /**
   * Função chamada ao selecionar uma conversa.
   *
   * @param {Object} selectedChat - O chat selecionado pelo usuário.
   */
  const handleConversationSelect = (selectedChat) => {
    if (Array.isArray(selectedChat) && selectedChat.length === 0) {
      setShowReportScreen(false);
      setMessages([]);
      setCurrentChatProtocol({});
      setCurrentChatHistoric([]);
      setCurrentChatKnowledge({});
      setCurrentChatType("");
      setCurrentChatSummary("");
    } else {
      setMessages(
        selectedChat.messages.map((message) => {
          return {
            id: message.id,
            role: message.role,
            question: message.role === "user" ? message.content : "",
            answer: message.role === "assistant" ? message.content : "",
            feedback: message.feedback,
          };
        })
      );
      setShowReportScreen(false);
      setCurrentChatHistoric(selectedChat.historic || []);
      setCurrentChatKnowledge(selectedChat.knowledge || {});
      setCurrentChatProtocol(selectedChat.protocol || {});
      setCurrentChatType(selectedChat.type || "");
      setCurrentChatSummary(selectedChat.summary || "");
    }
  };

  /**
   * Função para resetar a rolagem e a paginação do histórico.
   */
  const resetScrollAndPagination = () => {
    resetPagination();
    setScrollCheckpoint(0);
    setLastScrollPosition(0);
  };

  /**
   * Efeito que busca o histórico de chat inicial ao montar o componente.
   */
  useEffect(() => {
    getHistoric(1)
      .then((data) => {
        setChatData(data);
        resetScrollAndPagination();
      })
      .catch((error) => {});
  }, []);

  return (
    <div
      ref={containerRef}
      className="relative flex-1 max-h-full overflow-x-hidden overflow-y-auto select-none transform-gpu"
    >
      {history.map(([dateGroup, chats], index) => (
        <React.Fragment key={dateGroup}>
          <div
            className={`text-xs select-none ${
              index === 0 ? "mt-0" : "mt-6"
            } font-semibold leading-6 text-slate-200`}
          >
            {dateGroup}
          </div>
          <ul className="mt-2 -mx-2 space-y-1">
            {chats.map((chat) => (
              <div
                key={chat.protocol}
                onClick={() => {
                  handleConversationSelect(chat);
                  setCurrentSelection(chat.protocol);
                }}
                onMouseEnter={() => setEllipisisVisible(chat.protocol)}
                onMouseLeave={() => setEllipisisVisible(null)}
              >
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
                  className="z-50 flex p-2 text-sm font-normal leading-6 transition-colors duration-300 rounded-md cursor-pointer text-slate-400 group gap-x-3 hover:text-white"
                >
                  <span className="truncate">{chat.summary}</span>
                  <div className="absolute right-0 transition">
                    {ellipisisVisible === chat.protocol && (
                      <Ellipsis
                        isChatPinned={chat.pinned}
                        chatUuid={chat.protocol}
                        currentChatSummary={chat.summary}
                      />
                    )}
                  </div>
                </motion.div>
              </div>
            ))}
          </ul>
        </React.Fragment>
      ))}

      <div
        className="sticky -bottom-1 left-0 right-0 h-[100px]"
        style={{
          backgroundImage: "linear-gradient(to top, #111827, transparent)",
          pointerEvents: "none",
        }}
      ></div>
    </div>
  );
}
