import React from "react";
import { useChatContext } from "../../../contexts/ChatContext";
import {
  ChatBubbleLeftEllipsisIcon,
  DocumentIcon,
} from "@heroicons/react/24/outline";

/**
 * Componente de menu para selecionar uma nova conversa ou relatório.
 *
 * @component
 * @returns {JSX.Element} O menu de opções de chat ou relatório.
 */
export default function ChatMenu() {
  const {
    setMessages,
    setCurrentChatProtocol,
    setCurrentChatHistoric,
    setCurrentChatKnowledge,
    setShowReportScreen,
    setResetConversation,
    setOnlyRead,
    setIsPoll,
    setMobileNavBarOpen,
    currentSelection,
    setCurrentSelection,
    setCurrentChatType,
  } = useChatContext();

  /**
   * Função para lidar com a seleção de uma conversa.
   *
   * @param {Object[]} selectedChat - O chat selecionado pelo usuário.
   */
  const handleConversationSelect = (selectedChat) => {
    if (Array.isArray(selectedChat) && selectedChat.length === 0) {
      setShowReportScreen(false);
      setMessages([]);
      setCurrentChatProtocol({});
      setCurrentChatHistoric([]);
      setCurrentChatKnowledge({});
      setCurrentChatType("");
    } else {
      setMessages(
        selectedChat.messages.map((message) => {
          return {
            id: message.id,
            role: message.role,
            question: message.role === "user" ? message.content : "",
            answer: message.role === "assistant" ? message.content : "",
            feedback: message.feedback,
          };
        })
      );
      setShowReportScreen(false);
      setCurrentChatHistoric(selectedChat.historic || []);
      setCurrentChatKnowledge(selectedChat.knowledge || {});
      setCurrentChatProtocol(selectedChat.protocol || {});
      setCurrentChatType(selectedChat.type || "");
    }
  };

  return (
    <div>
      <div className="-mx-2 space-y-1">
        <div className="flex flex-col select-none">
          <div>
            <div
              className={`group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold transition-colors cursor-pointer duration-300 ${
                currentSelection === "Nova conversa"
                  ? "bg-slate-800 text-white"
                  : "text-slate-400 hover:text-white hover:bg-slate-800"
              }`}
              onClick={() => {
                handleConversationSelect([]);
                setOnlyRead(false);
                setIsPoll(false);
                setCurrentSelection("Nova conversa");
                setMobileNavBarOpen(false);
              }}
            >
              <ChatBubbleLeftEllipsisIcon
                className="w-6 h-6 shrink-0"
                aria-hidden="true"
              />
              Nova conversa
            </div>
          </div>
          <div>
            <div
              className={`group flex gap-x-3 cursor-pointer rounded-md p-2 text-sm leading-6 font-semibold transition-colors duration-300 ${
                currentSelection === "Novo relatório"
                  ? "bg-slate-800 text-white"
                  : "text-slate-400 hover:text-white hover:bg-slate-800"
              }`}
              onClick={() => {
                setShowReportScreen(true);
                setResetConversation(true);
                setCurrentChatProtocol({});
                setCurrentChatHistoric([]);
                setCurrentChatKnowledge({});
                setCurrentSelection("Novo relatório");
                setMobileNavBarOpen(false);
              }}
            >
              <DocumentIcon className="w-6 h-6 shrink-0" aria-hidden="true" />
              Novo relatório
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
