import React, { useState, useEffect, Fragment, useRef } from "react";
import { Transition } from "@headlessui/react";
import { useChatContext } from "../../../contexts/ChatContext";
import { downloadReport } from "../../../server/downloadReport";
import ReportDownloadButton from "./ReportDownloadButton/ReportDownloadButton";
import LoadMessage from "../LoadMessage/LoadMessage";
import UserAvatar from "../../UserAvatar/UserAvatar";
import MessageOptions from "./MessageOptions/MessageOptions";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkBreaks from "remark-breaks";
import { ArrowDownIcon } from "@heroicons/react/24/outline";
import * as XLSX from "xlsx";

/**
 * Componente Message.
 *
 * @component
 *
 * @description
 * O componente `Message` exibe uma mensagem de chat e lida com diferentes estados, como digitação, erro no servidor, e autenticação não autorizada. Ele também oferece suporte para download de relatórios e renderização de conteúdo em Markdown.
 * Utiliza o `useChatContext` para acessar o contexto de chat e exibe avatares, mensagens e opções de mensagem, dependendo do estado atual.
 *
 * @param {Object} props - Propriedades do componente.
 * @param {Object} props.message - A mensagem a ser exibida, contendo informações como `role`, `question`, `answer`, e `error_message`.
 * @param {boolean} [props.typing=false] - Indica se o assistente está digitando.
 * @param {boolean} [props.serverError=false] - Indica se há um erro de servidor.
 * @param {boolean} [props.showNotAuthenticatedError=false] - Indica se o usuário não está autenticado para acessar a funcionalidade.
 *
 * @returns {JSX.Element} O componente que exibe a mensagem de chat com animações e condições de estado.
 *
 * @example
 * const message = {
 *   role: "assistant",
 *   answer: "Aqui está o seu relatório!",
 * };
 * return <Message message={message} typing={false} />;
 */
const Message = ({
  message,
  typing = false,
  serverError = false,
  showNotAuthenticatedError = false,
}) => {
  const {
    resetConversation,
    setOnlyRead,
    setResetConversation,
    setShowMobileMessagesOptions,
    currentChatSummary,
  } = useChatContext();

  const [show, setShow] = useState(false);
  const touchTimeoutRef = useRef(null);
  const isTouchActiveRef = useRef(false);
  const tableRefs = useRef([]);

  useEffect(() => {
    if (resetConversation) {
      setResetConversation(false);
    }
  }, [resetConversation, setResetConversation]);

  useEffect(() => {
    if (message && message.answer) {
      const shouldSetReadOnly = message.answer.includes("reports/regenerate");
      setOnlyRead(shouldSetReadOnly);
    }
  }, [message, setOnlyRead]);

  async function handleLinkClick(link) {
    await downloadReport(link);
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, 1);
    return () => clearTimeout(timer);
  }, []);

  const handleTouchStart = () => {
    if (message.role === "assistant" && !isTouchActiveRef.current) {
      if (navigator.vibrate) navigator.vibrate(50);

      isTouchActiveRef.current = true;

      touchTimeoutRef.current = setTimeout(() => {
        setShowMobileMessagesOptions(message);
        isTouchActiveRef.current = false;
      }, 1500);
    }
  };

  const handleTouchEnd = () => {
    clearTimeout(touchTimeoutRef.current);
    isTouchActiveRef.current = false;
  };

  /**
   * Função para extrair os dados da tabela e criar um arquivo XLSX
   * @param {HTMLElement} tableElement - A tabela HTML a ser convertida em XLSX
   * @param {string} fileName - O nome do arquivo XLSX a ser gerado
   */
  function downloadTableAsXLSX(tableElement, fileName) {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.table_to_sheet(tableElement);
    XLSX.utils.book_append_sheet(wb, ws, "Planilha");

    // Defina o nome do arquivo dinamicamente ou use um nome padrão
    const finalFileName = fileName || "planilha.xlsx";

    // Gerar o arquivo e iniciar o download
    XLSX.writeFile(wb, finalFileName);
  }

  const handleDownloadClick = (tableIndex) => {
    const tableElement = tableRefs.current[tableIndex]; // Acessa a tabela correta pelo índice
    const fileName = `${currentChatSummary}_${tableIndex + 1}.xlsx`;

    // Verifique se a tabela foi encontrada e passe para o XLSX
    if (tableElement) {
      downloadTableAsXLSX(tableElement, fileName);
    } else {
      console.error("Tabela não encontrada!" + tableIndex);
    }
  };

  return (
    <Transition
      show={show}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition ease-in duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="relative">
        <div
          className="flex justify-start w-full max-w-full m-2 my-4 transition duration-100 ease-in-out smallMobile:select-none sm:select-text"
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
        >
          <UserAvatar
            chatting={true}
            message={message}
            isFromAssistant={typing || serverError || showNotAuthenticatedError}
          />
          <div
            className={`flex items-center flex-wrap w-full px-2 max-w-[750px] text-[#242424] dark:text-[#e5e7eb]`}
            onClick={() => {
              if (
                message.answer &&
                message.answer.includes("reports/regenerate")
              ) {
                handleLinkClick(message.answer);
              }
            }}
          >
            <div className="relative w-full">
              <div className="absolute top-0 font-bold select-none text-[#242424] dark:text-[#e5e7eb]">
                {typing || serverError || showNotAuthenticatedError
                  ? "Tadeo"
                  : message.role === "assistant"
                    ? "Tadeo"
                    : "Você"}
              </div>
              {message.role === "assistant" ? (
                <MessageOptions message={message} />
              ) : (
                ""
              )}
              <br />
              {/* text message */}
              <div
                className={`max-w-full text-pretty overflow-wrap smallMobile:break-words verticalTablet:break-normal verticalTablet:hyphens-auto ${
                  serverError ? "text-red-500 dark:text-red-400" : ""
                } prose dark:prose-dark`}
                lang="pt-BR"
              >
                {serverError ? (
                  "Desculpe-nos, mas parece que nosso servidor está enfrentando um problema momentâneo. Estamos cientes e trabalhando para corrigir isso. Por favor, tente novamente em breve."
                ) : showNotAuthenticatedError ? (
                  <div className="w-full text-red-500 dark:text-red-400">
                    Sua conta não possui autorização para acessar esta
                    funcionalidade. Solicitamos que por gentileza preencha o{" "}
                    <a
                      href="https://forms.clickup.com/9007081222/f/8cdu9r6-6511/H3MZRM238G3QLSROJP?Nome%20da%20tarefa=Solicitação%20de%20Acesso"
                      className="inline font-bold cursor-pointer hover:underline"
                    >
                      formulário de solicitação de acesso
                    </a>
                    .
                  </div>
                ) : typing ? (
                  <LoadMessage />
                ) : message.role === "assistant" ? (
                  message.answer.includes("reports/regenerate") ? (
                    <ReportDownloadButton />
                  ) : (
                    <ReactMarkdown
                      remarkPlugins={[remarkGfm, remarkBreaks]}
                      components={{
                        table: ({ node, ...props }) => {
                          const tableIndex = tableRefs.current.length;
                          return (
                            <>
                              <div className="flex smallMobile:w-[75svw] sm:w-[50svw] horizontalTablet:w-full select-none">
                                <div
                                  className="flex items-center justify-center my-1 ml-auto bg-slate-50/90 dark:bg-slate-900/90 h-[25px] w-[180px] rounded-md hover:dark:bg-slate-800/70 hover:bg-slate-200/70 backdrop-blur-lg backdrop-filter ring-inset ring-1 ring-slate-200/50 dark:ring-slate-600/50 cursor-pointer transition-colors duration-300"
                                  onClick={() =>
                                    handleDownloadClick(tableIndex)
                                  }
                                >
                                  <ArrowDownIcon className="size-[16px] pb-[1px]" />
                                  <p className="text-[12px] font-bold ml-3 pt-[1px]">
                                    Baixar planilha
                                  </p>
                                </div>
                              </div>
                              <div className="overflow-x-auto smallMobile:w-[75svw] sm:w-[50svw] horizontalTablet:w-full">
                                <table
                                  ref={(el) =>
                                    (tableRefs.current[tableIndex] = el)
                                  }
                                  className=" table-auto max-w-full text-[#242424] dark:text-[#e5e7eb] bg-slate-50/90 dark:bg-slate-900/90 rounded-xl px-5 backdrop-blur-lg backdrop-filter ring-inset my-[0px] ring-1 ring-slate-200/50 dark:ring-slate-600/50"
                                  {...props}
                                />
                              </div>
                            </>
                          );
                        },
                        th: ({ node, ...props }) => (
                          <th
                            className="whitespace-nowrap text-[#242424] dark:text-[#e5e7eb] border-b dark:border-slate-600 border-slate-300 mx-2 px-5 pt-3"
                            {...props}
                          />
                        ),
                        td: ({ node, ...props }) => (
                          <td
                            className="text-[#242424] dark:text-[#e5e7eb] border-b dark:border-slate-600 border-slate-300 mx-2 text-nowrap px-5"
                            {...props}
                          />
                        ),
                        h1: ({ node, ...props }) => (
                          <h1
                            className="text-[#242424] dark:text-[#e5e7eb]"
                            {...props}
                          />
                        ),
                        h2: ({ node, ...props }) => (
                          <h2
                            className="text-[#242424] dark:text-[#e5e7eb]"
                            {...props}
                          />
                        ),
                        h3: ({ node, ...props }) => (
                          <h3
                            className="text-[#242424] dark:text-[#e5e7eb]"
                            {...props}
                          />
                        ),
                        p: ({ node, ...props }) => (
                          <p
                            className="text-[#242424] dark:text-[#e5e7eb]"
                            {...props}
                          />
                        ),
                      }}
                    >
                      {message.answer || message.error_message}
                    </ReactMarkdown>
                  )
                ) : (
                  message.question
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
};

export default Message;
